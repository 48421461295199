import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const AddButton = styled.button`
  ${({ theme }) => css`
    border: none;
    background: none;
    color: ${theme.colors.tertiary};
    padding: 0;
    font-size: 1rem;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    color: black;
    height: 28px;
    min-width: 100px;
    border: 1px solid ${theme.colors.secondary};
    border-radius: 4px;
    padding: 3px 6px;
    width: calc(100% - 20px);
  `}
`;

export const ActionBtn = styled.span`
  width: 18px;
  height: 18px;
  display: inline-block;
`;

export const Holder = styled.div`
  position: relative;
  height: 28px;
`;

export const Actions = styled.div`
  ${({ theme }) => css`
  position: absolute;
  top:0;
  z-index: 111;
  right:-60px;
  bottom:0;
  background ${theme.colors.greys[100]}`}
  width:70px;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  align-items: center;
`;
