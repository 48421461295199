import React, { useState } from "react";
import PropTypes from "prop-types";

const Pagination = ({
  canPreviousPage,
  canNextPage,
  nextPage,
  pageCount,
  previousPage,
  gotoPage,
  currPage = 1,
  setCurrPage = null,
  pageIndex
}) => {
  const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  const getVisiblePages = (page, total) => {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  const [visiblePages, setVisiblePages] = useState(() =>
    getVisiblePages(currPage, pageCount)
  );

  const changePage = page => {
    const activePage = pageIndex + 1;

    const total = pageCount;

    if (page === activePage) {
      return;
    }
    const visiblePages = getVisiblePages(page, total);
    const filteredItems = filterPages(visiblePages, total);
    setVisiblePages(filteredItems);
  };

  const previousText = "<";
  const nextText = ">";

  return (
    <div>
      <button
        className="change-page"
        onClick={() => {
          changePage(pageIndex - 1);
          previousPage();
          setCurrPage && setCurrPage(currPage - 1);
        }}
        disabled={!canPreviousPage}
      >
        {previousText}
      </button>
      {visiblePages.map((page, index, array) => {
        return (
          <button
            className={`page-btn ${page === pageIndex + 1 ? "active" : ""}`}
            key={page}
            onClick={() => {
              gotoPage(page - 1);
              setCurrPage && setCurrPage(page);
              changePage(page - 1);
            }}
          >
            {array[index - 1] + 2 < page ? `...  ${page}` : page}
          </button>
        );
      })}
      <button
        className="change-page"
        onClick={() => {
          changePage(pageIndex + 1);
          nextPage();
          if (setCurrPage) {
            setCurrPage(currPage + 1);
          }
        }}
        disabled={!canNextPage}
      >
        {nextText}
      </button>
    </div>
  );
};

Pagination.propTypes = {
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  nextPage: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  gotoPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired
};

export default Pagination;
