import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";

export const StyledInput = styled.div`
  ${({ theme }) => css`
    display: flex;

    input {
      width: -webkit-fill-available;
      display: block;
      color: black;
      font-size: 13px;
      padding: 6px 12px;
      background-color: white;
      border: 1px solid;
      border-color: ${theme.colors.greys[500]};
      border-radius: 4px 0 0 4px;
      border-right: 0;
      height: 37px;
      font-family: ${theme.fonts.body};
      font-weight: 400px;

      &::placeholder {
        font-family: ${theme.fonts.body};
        font-weight: 400px;
        color: ${theme.colors.greys[500]};
      }
    }

    .InputAddOn-field {
      width: 135px;
      flex: 1;
      border-radius: 4px 0 0 4px;
      border-right: 0;
    }
    .InputAddOn-field:not(:first-of-type) {
      border-left: 0;
    }
    .InputAddOn-field:not(:last-of-type) {
      border-right: 0;
    }

    .InputAddOn-item {
      background-color: ${theme.colors.highlight};
      color: ${theme.colors.greys[600]};
      font: inherit;
      max-height: 37px;
      font-weight: 400px;
      border: 1px solid;
      border-color: ${theme.colors.greys[500]};
      font-family: ${theme.fonts.body};
    }

    .InputAddOn-item {
      padding: 6px 8px;
    }
    .InputAddOn-item:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    .InputAddOn-item:last-of-type {
      border-radius: 0 4px 4px 0;
    }
  `};
`;

export default styled.div`
  ${({ theme }) => css`
    .select-search {
      width: auto;
      position: relative;
      font-family: ${theme.fonts.body};
      box-sizing: border-box;

      &.active {
        .select-search__value .select-search__input {
          background: rgb(69, 69, 69);
          color: ${theme.colors.white};
        }
        &:not(.is-loading):not(.select-search--multiple)
        .select-search__value::after {
          border-right: 1px solid ${theme.colors.white};
          border-bottom: 1px solid ${theme.colors.white};
        }
        }
        &::placeholder {
          color: ${theme.colors.white};
        }
      }
    }

    .select-search *,
    .select-search *::after,
    .select-search *::before {
      box-sizing: inherit;
    }

    .select-search__value {
      position: relative;
      z-index: 1;
    }

    .select-search__value::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: calc(50% - 9px);
      right: 19px;
      width: 11px;
      height: 11px;
    }

    .select-search__input {
      display: block;
      height: 36px;
      outline: none;
      text-align: left;
      text-overflow: ellipsis;
      line-height: 36px;
      -webkit-appearance: none;
      width: -webkit-fill-available;
      font-family: ${theme.fonts.body};
      font-weight: 400px;
      font-size: 13px;
      color: ${theme.colors.greys[600]};
      padding: 6px 12px;
      background-color: ${theme.colors.white};
      border: 1px solid;
      border-color: ${theme.colors.greys[500]};
      border-radius: 4px;
      height: 37px;

      &::placeholder {
        font-family: ${theme.fonts.body};
        font-weight: 400px;
        color: ${theme.colors.greys[500]};
        font-size: 13px;
      }
    }

    .select-search__input::-webkit-search-decoration,
    .select-search__input::-webkit-search-cancel-button,
    .select-search__input::-webkit-search-results-button,
    .select-search__input::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    .select-search__input:not([readonly]):focus {
      cursor: initial;
    }

    .select-search__select {
      background:  ${theme.colors.white};
      box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    }

    .select-search__options {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .select-search__row:not(:first-of-type) {
      border-top: 1px solid ${theme.colors.highlight};
    }

    .select-search__option {
      display: block;
      height: 36px;
      width: 100%;
      padding: 0 16px;
      background:  ${theme.colors.white};
      border: none;
      outline: none;
      font-family: ${theme.fonts.body};
      font-size: 14px;
      text-align: left;
      cursor: pointer;
    }

    .select-search--multiple .select-search__option {
      height: 48px;
    }

    .select-search__option.is-selected {
      background: ${theme.colors.tertiary};
      color: ${theme.colors.white};
    }

    .select-search__option.is-highlighted,
    .select-search__option:not(.is-selected):hover {
      background: ${theme.colors.turquoise};
    }

    .select-search__option.is-highlighted.is-selected,
    .select-search__option.is-selected:hover {
      background: ${theme.colors.tertiary};
      color: ${theme.colors.white};
    }

    .select-search__group-header {
      font-size: 10px;
      text-transform: uppercase;
      background: ${theme.colors.highlight};
      padding: 8px 16px;
    }

    .select-search.is-disabled {
      color: ${theme.colors.greys[500]};
      border-color: ${theme.colors.greys[150]};
    }

    .select-search.is-loading .select-search__value::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
      background-size: 11px;
    }

    .select-search:not(.is-disabled) .select-search__input {
      cursor: pointer;
    }

    .select-search--multiple {
      border-radius: 3px;
      overflow: hidden;
    }

    .select-search:not(.is-loading):not(.select-search--multiple)
      .select-search__value::after {
      transform: rotate(45deg);
      border-right: 1px solid ${theme.colors.black};
      border-bottom: 1px solid ${theme.colors.black};
      pointer-events: none;
    }

    .select-search--multiple .select-search__input {
      cursor: initial;
    }

    .select-search--multiple .select-search__input {
      border-radius: 3px 3px 0 0;
    }

    .select-search--multiple:not(.select-search--search) .select-search__input {
      cursor: default;
    }

    .select-search:not(.select-search--multiple) .select-search__input:hover {
      border-color:  ${theme.colors.greys[500]}; 
    }

    .select-search:not(.select-search--multiple) .select-search__select {
      position: absolute;
      z-index: 2;
      top: 44px;
      right: 0;
      left: 0;
      border-radius: 3px;
      overflow: auto;
      max-height: 360px;
    }

    .select-search--multiple .select-search__select {
      position: relative;
      overflow: auto;
      max-height: 260px;
      border-top: 1px solid ${theme.colors.highlight};
      border-radius: 0 0 3px 3px;
    }
  `}
`;

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: "#888888",
    height: state.isMulti ? "auto" : 38,
    minHeight: 38
  }),
  singleValue: provided => ({
    ...provided,
    top: 22
  }),
  placeholder: provided => ({
    ...provided,
    position: "sticky",
    fontSize: 13,
    top: 18
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: state.isMulti && state.hasValue ? "auto" : 38,
    maxHeight: state.isMulti && state.hasValue && 140,
    overflow: state.isMulti && state.hasValue ? "scroll" : "hidden",
    fontSize: "15px",
    padding: "1px 8px"
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: 38
  })
};

export const filterStyles = {
  container: provided => ({
    ...provided,
    width: 180,
    minWidth: 180
  }),
  control: (provided, state) => {
    return {
      ...provided,
      backgroundColor: state.hasValue ? "#454545" : "#f6f6f6",
      height: 34,
      minHeight: 34,
      borderRadius: "6px",
      boxShadow: "none"
    };
  },
  singleValue: (provided, state) => ({
    ...provided,
    color: state.hasValue ? "white" : "inherit",
    top: 16
  }),
  placeholder: provided => ({
    ...provided,
    position: "absolute",
    color: "#454545",
    fontSize: 13,
    top: 16
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 34,
    color: state.hasValue ? "white" : "inherit",
    padding: "0px 8px"
  }),
  input: (provided, state) => ({
    ...provided,
    color: state.hasValue ? "white" : "inherit"
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: 34
  }),
  dropdownIndicator: provided => ({
    ...provided,
    display: "none"
  }),
  clearIndicator: provided => ({
    ...provided,
    color: "white"
  }),
  indicatorSeparator: provided => ({
    ...provided,
    height: 34,
    display: "none",
    minHeight: 34
  })
};
