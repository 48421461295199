import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ThemeProvider, Themed } from "theme-ui";
import theme from "./theme";
import * as serviceWorker from "./serviceWorker";
import apolloClient from "api/apollo-client";
import { ApolloProvider } from "@apollo/client";
import ErrorBoundary from "components/ErrorBoundary";

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <Themed.div>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </Themed.div>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
