/** @jsx jsx */
import { jsx } from "theme-ui";
import Switch from "react-switch";
import { SyntheticEvent } from "react";
import styled from "@emotion/styled";

interface props {
  checked: boolean;
  handleChange: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string
  ) => void;
  text?: string;
  disabled?: boolean;
}

const SwitchComponent = ({
  checked,
  handleChange,
  text,
  disabled
}: props): JSX.Element => {
  return (
    <SwitchWrapper>
      <Switch
        className="switch-btn"
        onChange={handleChange}
        checked={checked}
        handleDiameter={16}
        boxShadow="0px 3px 6px #00000052"
        height={18}
        width={36}
        onColor="#C3E4FF"
        onHandleColor="#007EE5"
        offColor="#BBBBBB"
        offHandleColor="#888888"
        uncheckedIcon={false}
        checkedIcon={false}
        disabled={disabled}
      />
      <TextWrapper>{text}</TextWrapper>
    </SwitchWrapper>
  );
};

export default SwitchComponent;

const SwitchWrapper = styled.div`
  font-weight: 600;
  display: flex;
`;

const TextWrapper = styled.div`
  margin-left: 6px;
`;
