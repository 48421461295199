import React, { useState } from "react";
import { Button } from "theme-ui";

import ADDICON from "assets/add-icon.svg";
import { ImageUploader } from "components";
import { HorizontalRule } from "styles";

import Modal from "./index";
import {
  Image,
  UploadedImage,
  AddImage,
  TitleWrapper,
  View,
  ShopImage
} from "./styles";

const PhotoModal = ({
  image_url,
  title = ""
}: {
  image_url: string;
  title: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Image
        onClick={event => {
          event.stopPropagation();
          setIsOpen(true);
        }}
        src={image_url}
        alt="shop image"
        loading="lazy"
      />
      <Modal open={isOpen} onClose={() => setIsOpen(false)} title={title}>
        <ShopImage src={image_url} alt="view shop" />
      </Modal>
    </div>
  );
};

type UploadImgModalProps = {
  image_url: string;
  title: string;
  item_name: string;
  itemId: string;
  handleSubmitImage: ({
    imageFile,
    itemId
  }: {
    imageFile: File | null;
    itemId: string;
  }) => void;
};

export const UploadImgModal = ({
  image_url,
  title = "",
  item_name,
  handleSubmitImage,
  itemId
}: UploadImgModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);

  return (
    <div>
      {image_url ? (
        <UploadedImage
          onClick={() => setIsOpen(true)}
          src={image_url}
          alt="uploaded image"
          loading="lazy"
        />
      ) : (
        <AddImage
          onClick={() => setIsOpen(true)}
          src={ADDICON}
          alt="upload icon"
          loading="lazy"
        />
      )}
      <Modal open={isOpen} onClose={() => setIsOpen(false)} title={title}>
        <TitleWrapper>{item_name}</TitleWrapper>
        <View>
          <ImageUploader
            width={225}
            height={225}
            image_url={image_url}
            setImageFile={setImageFile}
          />
          <HorizontalRule />
          <div>
            <Button
              type="button"
              variant="cancel"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmitImage({ itemId, imageFile })}
              type="button"
              variant="secondary"
            >
              Save Image
            </Button>
          </div>
        </View>
      </Modal>
    </div>
  );
};

export default PhotoModal;
