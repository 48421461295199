import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Alert = styled.div`
  ${({ theme }) => css`
    position: relative;
    color: ${theme.colors.alert[100]};
    background: ${theme.colors.alert[200]};
    border: 1px solid ${theme.colors.alert[300]};
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    white-space: break-spaces;
    &.info {
      color: ${theme.colors.info[100]};
      background-color: ${theme.colors.info[200]};
      border-color: ${theme.colors.info[300]};
    }
    &.primary {
      color: ${theme.colors.alertPrimary[100]};
      background-color: ${theme.colors.alertPrimary[200]};
      border-color: ${theme.colors.alertPrimary[300]};
    }
    &.success {
      color: ${theme.colors.success[100]};
      background-color: ${theme.colors.success[200]};
      border-color: ${theme.colors.success[300]};
    }
    &.danger {
      color: ${theme.colors.danger[100]};
      background-color: ${theme.colors.danger[200]};
      border-color: ${theme.colors.danger[300]};
    }
    &.warning {
      color: ${theme.colors.warning[100]};
      background-color: ${theme.colors.warning[200]};
      border-color: ${theme.colors.warning[300]};
    }
    &.light {
      color: ${theme.colors.light[100]};
      background-color: ${theme.colors.light[200]};
      border-color: ${theme.colors.light[300]};
    }
  `}
`;

export default Alert;
