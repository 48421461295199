import React, { useState, useContext, createContext, useCallback } from "react";
import { baseClient, client } from "api";
import { useLocalStorage, writeStorage } from "@rehooks/local-storage";
import { formatError } from "utils";
import PropTypes from "prop-types";

const { REACT_APP_CLIENT_ID: client_id, REACT_APP_REDIRECT_URL } = process.env;

const AuthContext = createContext();

export const LOCAL_STORE_USER_KEY = "twiga_dashboard_user";
export const LOCAL_STORE_TOKEN = "twiga_dashboard_token";

export function AuthProvider({ children }) {
  const auth = useContext(AuthContext);
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// this should be returned on login
const countries = [
  "17d349d8-f114-4b23-a53a-abbd33da23e9",
  "067223a9-053e-4680-99a5-00a6ca1a4d94",
  "120ac035-0fac-49ca-8102-47a7d564005e"
];

export function useAuth() {
  const [user, setUser, deleteUser] = useLocalStorage(
    LOCAL_STORE_USER_KEY,
    null
  );
  const [codeVerifier] = useLocalStorage("codeVerifier_", null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);

  const onLogout = () => {
    deleteUser();
    setError(null);
    setUser(null);
    setResponseMessage(null);
  };

  const onLogin = useCallback(
    async code => {
      let response = {};
      try {
        setError(null);
        setResponseMessage(null);
        setLoading(true);
        writeStorage(LOCAL_STORE_TOKEN, null);
        const credentials = {
          code,
          client_id,
          grant_type: "authorization_code",
          code_verifier: codeVerifier,
          redirect_uri: REACT_APP_REDIRECT_URL
        };
        const { data: token } = await baseClient.post(
          "/token/oauth2/token",
          credentials
        );
        if (!token.access_token) {
          throw new Error("Invalid credentials. Please try again");
        }
        writeStorage(LOCAL_STORE_TOKEN, token);
        const { data: user } = await client.get("/user-profile");
        const userData = {
          ...user,
          country_ids: countries.slice(0, 2),
          default_country_id: countries[0]
        };
        userData.countries = [
          ...new Set(userData.country_ids.concat([userData.default_country_id]))
        ];

        setUser(userData);
        setLoading(false);
      } catch (e) {
        const { description, status } = formatError(e);
        setError(description);
        setLoading(false);
        response = { error: { description, status } };
      }
      return response;
    },
    [codeVerifier, setUser]
  );

  const hasPermission = permission => {
    if (!permission) return false;
    const userPermissions = user?.permissions || [];
    return userPermissions.indexOf(permission) > -1;
  };

  return {
    error,
    loading,
    user,
    onLogin,
    onLogout,
    responseMessage,
    hasPermission
  };
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
