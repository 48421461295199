import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DatePickerComponent = ({ date, onChange, ...props }) => {
  return (
    <DatePicker
      className="datepicker"
      dateFormat="dd MMMM yyyy"
      selected={date}
      onChange={onChange}
      {...props}
    />
  );
};

export default DatePickerComponent;
