/** @jsx jsx */
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import { jsx } from "theme-ui";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const TooltipStyles = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fonts.body};
    left: 1421px;

    .overlay-tooltip {
      .rc-tooltip {
        opacity: 1;
        .rc-tooltip-content {
          .rc-tooltip-placement-bottomLeft {
            .rc-tooltip-arrow {
              left: 9%;
              border-bottom-color: white;
            }
          }
        }
      }
    }
  `}
`;

const ConfirmDialog = ({ children, overlayContent, visible }) => {
  return (
    <TooltipStyles>
      <Tooltip
        overlayStyle={{
          opacity: 1,
          minWidth: "100px",
          maxWidth: "240px",
          left: 1421,
          boxShadow: "0px 3px 6px #00000029",
          zIndex: 99999
        }}
        overlayInnerStyle={{
          color: "black",
          fontSize: "13px",
          border: "transparent"
        }}
        overlayClassName="overlay-tooltip"
        placement="bottomLeft"
        trigger={["click"]}
        overlay={overlayContent}
        visible={visible}
      >
        {children}
      </Tooltip>
    </TooltipStyles>
  );
};

export default ConfirmDialog;
