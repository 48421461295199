import { Alert } from "./alert";
import { FormLayout, ModalForm, HorizontalRule, LoadingSpinner } from "./form";
import { ImageHolder } from "./image";
import { SubRowTable } from "./subrow-table";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const TableExpander = styled.span`
  img {
    vertical-align: middle;
  }
`;

const PlusImg = styled.img`
  margin-right: 12px;
`;

const EditIconWrapper = styled.img`
  ${({ theme }) => css`
    width: 30px;
    height: 30px;
    border: 1px solid;
    border-color: ${theme.colors.greys[350]};
    border-radius: 50%;
    padding: 4px;
    cursor: pointer;
  `}
`;

export {
  Alert,
  FormLayout,
  ModalForm,
  TableExpander,
  HorizontalRule,
  ImageHolder,
  PlusImg,
  EditIconWrapper,
  SubRowTable,
  LoadingSpinner
};
