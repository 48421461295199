import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Theme, ColorMode } from "theme-ui";

interface themeProps extends Theme {
  colors: ColorMode;
  fontWeights: any;
}
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10001;
`;

export const ModalStyles = styled.div`
  ${({ theme }: { theme?: themeProps }) => css`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${theme?.colors.white};
    z-index: 10001;
    border-radius: 4px;
    width: 500px;
  `}
`;

export const CloseIcon = styled.span`
  position: absolute;
  top: -18px;
  left: 470px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  z-index: 10002;
`;

export const TitleContainer = styled.div`
  ${({ theme }: { theme?: themeProps }) => css`
    background: ${theme?.colors.primary};
    height: 50px;
    color: ${theme?.colors.white};
    text-align: center;
    font-size: 18px;
    padding: 15px 0;
    font-weight: ${theme?.fontWeights.bold};
  `}
`;

export const TitleWrapper = styled.div`
  text-align: center;
  font-weight: 600;
  margin: 20px 0px;
`;

export const View = styled.div`
  margin-bottom: 20px;
  text-align: -webkit-center;
`;

export const UploadedImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
`;

export const AddImage = styled.img`
  width: 28px;
  height: 28px;
  cursor: pointer;
  margin: 0 5px;
`;

export const Image = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  max-height: 40px;
  cursor: pointer;
`;

export const ShopImage = styled.img`
  width: inherit;
  height: auto;
  padding: 5px 5px 0px;
  max-width: 100%;
`;
