import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { ImgPreview, UploadImageWrapper, View } from "./styles";

interface props {
  image_url?: string;
  width?: number;
  height?: number;
  isSubmitting?: boolean;
  setImageFile: Dispatch<SetStateAction<File | null>>;
}

export const ImageUploader = ({
  image_url,
  setImageFile,
  isSubmitting,
  width,
  height
}: props) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState(image_url);

  useEffect(() => {
    if (selectedFile) {
      const objectUrl: string = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  useEffect(() => {
    if (isSubmitting) {
      handleFileRemove();
    }
  });

  useEffect(() => {
    if (image_url) {
      setPreview(image_url);
    }
  }, [image_url]);

  const onFileChange: fileChange = event => {
    setPreview(image_url);
    const {
      target: { files }
    } = event;

    if (files !== null) {
      const file: File = files[0];
      setSelectedFile(file);
      setImageFile(file);
    }
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
    setPreview(image_url);
  };

  const FileData = () => {
    return (
      <div>
        <p>
          {selectedFile?.name}
          <span onClick={handleFileRemove} className="remove-file">
            Remove
          </span>
        </p>
      </div>
    );
  };

  return (
    <View>
      <UploadImageWrapper width={width} height={height}>
        <label className="custom-file-upload">
          <input onChange={onFileChange} type="file" accept=".png,.jpg" />
          {preview ? (
            <>
              <ImgPreview src={preview} alt="uploaded file" />
              {image_url ? <div>Update Image</div> : null}
            </>
          ) : (
            <div>+ Upload Image</div>
          )}
        </label>
      </UploadImageWrapper>
      {selectedFile ? <FileData /> : null}
    </View>
  );
};

export default ImageUploader;

type fileChange =
  | ((event: React.ChangeEvent<HTMLInputElement>) => void)
  | undefined;
