import { Theme } from "theme-ui";

const theme: Theme = {
  fonts: {
    body: '"Poppins", sans-serif',
    heading: '"Poppins", sans-serif',
    monospace: "Menlo, monospace"
  },
  fontWeights: {
    body: 400,
    bold: 700,
    semiBold: 600
  },
  fontSizes: [8, 10, 12, 14, 16, 18],
  radii: [4, 8],

  space: {
    marginBottom: [5, 10, 15, 20, 25, 30],
    padding: [4, 8, 12, 16],
    topMenuPadding: "13px 13px 13px 27px",
    subMenuPadding: "11px 13px 11px 35px"
  },
  borders: {
    borderBottom: "1px solid rgba(209, 209, 209, 0.5)",
    menuBorder: "1px solid rgba(209, 209, 209, 0.2)"
  },
  colors: {
    white: "#FFFFFF",
    cloudWhite: "#fafafa",
    grey100: "#f6f6f6",
    grey150: "#d9d9d9",
    grey300: "#000000a6",
    grey500: "#888888",
    grey550: "#999999",
    grey600: "#454545",
    grey700: "#BBBBBB",
    error: "#ED1212",
    primary: "#1C1C53",
    secondary: "#FECF0A",
    text: "#1C1C53",
    purple: "#8579FD",
    tertiary: "#007EE5",
    black: "#000000",
    border: "#1C1C5326",
    orange: "#F25E25",
    butterCup: "#f5a613",
    green: "#4D8A31",
    badgeGreen: "#52c51a",
    accent: "#FFFAE6",
    highlight: "#EEEEEE",
    background2: "#f1f1fa",
    background: "#F9F9FD",
    lightBlue: "#ECECF9",
    darkBlue: "#1A90FF",
    menuhover: "#28285c",
    background3: "#fddfdf",
    notificationsError: "#ED1212",
    chocolate: "#ED6612",
    darkslateblue: "#403394",
    blues: {
      100: "#B9B9E7",
      200: "#007EE5",
      300: "#C3E4FF",
      400: "#E5F3FF"
    },
    notifications: {
      green: "#B9E0A7",
      green_color: "#31581F",
      error: "#ED1212",
      error_color: "#FFFFFF",
      info: "#F9B195",
      info_color: "#C9400A"
    },
    alert: {
      100: "#383d41",
      200: "#e2e3e5",
      300: "#d6d8db"
    },
    info: {
      100: "#0c5460",
      200: "#d1ecf1",
      300: "#bee5eb"
    },
    alertPrimary: {
      100: "#004085",
      200: "#cce5ff",
      300: "#b8daff"
    },
    success: {
      100: "#155724",
      200: "#d4edda",
      300: "#c3e6cb"
    },
    danger: {
      100: "#721c24",
      200: "#f8d7da",
      300: "#f5c6cb"
    },
    warning: {
      100: "#856404",
      200: "#fff3cd",
      300: "#ffeeba"
    },
    light: {
      100: "#818182",
      200: "#fefefe",
      300: "#fdfdfe"
    },
    greys: {
      50: "#ececf9",
      100: "#f6f6f6",
      150: "#d9d9d9",
      200: "#e8e8e8",
      250: "#f5f5f5",
      300: "#a3a3a3",
      350: "#ececec",
      400: "#707070",
      500: "#888888",
      600: "#454545",
      700: "#BBBBBB",
      800: "#c2c2c2",
      900: "#dcd8d8"
    }
  },

  buttons: {
    default: {
      border: "none",
      padding: 0,
      color: "blues.200",
      fontWeight: "semiBold",
      textDecoration: "underline",
      marginRight: 3,
      borderColor: "greys.600",
      height: 30,
      backgroundColor: "transparent",
      cursor: "pointer",
      outline: "none",
      boxShadow: "none"
    },

    primary: {
      backgroundColor: "blues.200",
      height: 30,
      border: "none",
      color: "white",
      padding: "6px 12px",
      fontWeight: "semiBold"
    },

    cancel: {
      color: "primary",
      fontWeight: 600,
      marginRight: 16,
      border: "none",
      backgroundColor: "highlight",
      cursor: "pointer",
      padding: "6px 12px",
      fontFamily: "body",
      height: 33
    },

    delete: {
      border: "none",
      backgroundColor: "transparent",
      padding: 0,
      color: "greys.500",
      fontWeight: "semiBold",
      textDecoration: "underline",
      cursor: "pointer",
      outline: "none"
    },

    edit: {
      backgroundColor: "blues.400",
      borderRadius: 4,
      width: 50,
      height: 30,
      color: "blues.200"
    },

    okmodal: {
      fontSize: "10px",
      height: 25,
      padding: 0,
      cursor: "pointer"
    },

    cancelmodal: {
      fontSize: "10px",
      height: 25,
      padding: 0,
      cursor: "pointer",
      backgroundColor: "red"
    },

    blue: {
      bg: "blues.400",
      color: "blues.200",
      padding: "12px 15px",
      cursor: "pointer",
      fontWeight: "600",
      transition: "linear",
      "& > a": {
        color: "blues.200",
        fontWeight: "600",
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      "&:hover": {
        background: "#cce7ff",
        color: "primary",
        ".icon": {
          color: "orange"
        }
      }
    },

    secondary: {
      bg: "secondary",
      color: "primary",
      border: " none",
      cursor: "pointer",
      fontWeight: "semiBold",
      minWidth: 150,
      padding: "6px 12px",
      fontFamily: "body",
      transition: "all 0.25s linear",
      ":disabled": {
        cursor: "not-allowed",
        opacity: "0.7"
      }
    },

    cancellation: {
      bg: "notifications.error",
      color: "white",
      border: " none",
      cursor: "pointer",
      fontWeight: "semiBold",
      minWidth: 150,
      padding: "6px 12px",
      fontFamily: "body",
      transition: "all 0.25s linear",
      ":disabled": {
        cursor: "not-allowed",
        opacity: "0.7"
      },

      "&.rightAlign": {
        float: "right",
        margin: "10px"
      }
    },
    cancelProduct: {
      bg: "colors.primary",
      color: "white",
      border: " none",
      cursor: "pointer",
      fontWeight: "semiBold",
      minWidth: 100,
      padding: "6px 12px",
      fontFamily: "body",
      transition: "all 0.25s linear"
    },

    text: {
      color: "greys.500",
      backgroundColor: "transparent",
      marginRight: 3,
      outline: "none",
      textDecoration: "underline",
      border: "none",
      fontWeight: "semiBold",
      cursor: "pointer",

      "&:active": {
        color: "primary"
      }
    },

    plus: {
      marginTop: 40,
      borderRadius: "50%",
      width: "35px",
      height: "35px",
      textAlign: "center",
      lineHeight: "35px",
      padding: 0,
      fontSize: "22px",
      cursor: "pointer",
      outline: "none",
      border: "none"
    },

    addNew: {
      margin: "10px 0 20px",
      padding: "6px 12px",
      height: 33,
      cursor: "pointer",
      color: "white",
      backgroundColor: "blues.200",
      display: "inline-block",
      textAlign: "center",
      border: "none",
      fontWeight: "semiBold"
    }
  },
  forms: {
    input: {
      position: "relative",
      background: "white",
      marginRight: 30,
      borderRadius: 4,
      outline: "none",
      transition: "all 0.25s linear",
      color: "black",
      padding: 12,
      height: 30,
      width: 180,
      fontSize: 3,
      border: "none",
      "&::placeholder": {
        color: "greys.500"
      }
    },

    select: {
      fontFamily: "body",
      fontWeight: "500px",
      textAlign: "left",
      width: "auto",
      minWidth: 80,
      outline: "none",
      borderRadius: 6,
      backgroundColor: "greys.100",
      border: "1px solid greys.500",
      color: "greys.500"
    }
  },
  styles: {
    root: {
      backgroundColor: "background",
      fontSize: 3,
      fontFamily: "body",
      color: "primary",
      fontWeight: "body",
      "*: disabled": {
        cursor: "not-allowed"
      },

      input: {
        borderRadius: 4
      },
      a: {
        color: "blues.200"
      },
      h1: {
        fontSize: 5,
        color: "black",
        textAlign: "left",
        fontWeight: "bold",
        mt: 3
      },

      h2: {
        fontSize: 4,
        color: "primary",
        fontWeight: "semiBold"
      },

      table: {
        backgroundColor: "white",
        tableLayout: "fixed",
        borderSpacing: 0,
        maxWidth: "100%",
        outline: "none",
        padding: 0,
        fontFamily: "body",
        marginRight: 30,
        borderRadius: "4px 4px 0 0",
        border: "none",
        textAlign: "left",
        color: "primary",
        thead: {
          fontFamily: "body",
          backgroundColor: "lightBlue",
          th: {
            padding: "6px 8px",
            border: "none",
            fontWeight: "semiBold"
          }
        },
        tbody: {
          fontFamily: "body",
          color: "primary",
          borderRadius: 4,

          tr: {
            "&:hover": {
              backgroundColor: "blues.400"
            },

            ".form-table": {
              backgroundColor: "highlight",
              marginTop: 12
            }
          },
          td: {
            padding: "9px 8px",
            borderBottom: "1px solid",
            borderColor: "background",
            "&:first-of-type": {
              padding: "0 15px"
            },

            input: {
              margin: 0,
              border: 0
            }
          },

          hr: {
            border: "1px solid",
            borderColor: "border"
          }
        }
      }
    },

    div: {
      // style with classNames
      fontFamily: "body",
      margin: 0,
      padding: 0,

      ".capitalize": {
        textTransform: "uppercase"
      },

      ".expanded-row": {
        backgroundColor: "accent",
        "&:hover": {
          backgroundColor: "accent"
        },

        tr: {
          "&:hover": {
            backgroundColor: "accent"
          }
        }
      },

      ".unconfirmed": {
        color: "orange",
        textDecoration: "underline"
      },

      ".logo": {
        display: "block"
      },

      ".login-form": {
        Input: {
          paddingLeft: 30,
          margin: 0,
          height: 42,
          width: "100%",
          border: "none",
          backgroundColor: "background"
        },

        ".input-icon": {
          position: "absolute",
          top: 0,
          color: "greys.500",
          bottom: 0,
          margin: "auto",
          left: 10,
          fontSize: 5
        },

        ".icon": {
          marginRight: 10
        }
      },

      ".pagination-flex": {
        justifyContent: "space-between"
      },

      ".pagination-box": {
        marginTop: 15,
        display: "inline-flex"
      },

      ".pagination": {
        marginTop: 15,
        justifyContent: "flex-end"
      },

      ".view-card": {
        height: "auto",
        borderRight: "1px solid",
        borderColor: "border",
        backgroundColor: "background",

        span: {
          color: "greys.500",
          fontSize: "13px",
          fontWeight: 600
        },

        h1: {
          color: "black",
          fontWeight: 600,
          paddingBottom: 9,
          marginTop: 0,
          fontSize: "15px",
          borderBottom: "1px dashed",
          borderColor: "greys.700"
        },

        ".center": {
          margin: "0 31px 0 15px",
          padding: "30px 15px 60px 15px",
          background: "white",
          width: 300,
          position: "relative",

          ".icon": {
            backgroundColor: "blues.400",
            width: 50,
            height: 30,
            borderRadius: "4px",
            display: "block",
            position: "absolute",
            right: 3,
            top: 10,
            paddingTop: 9,
            cursor: "pointer",

            img: {
              width: "12px",
              height: "12px",
              display: "block",
              margin: "auto"
            }
          }
        },

        ".detail-card": {
          marginLeft: 30,
          width: "auto"
        }
      },

      ".page-btn": {
        border: "none",
        color: "greys.500",
        margin: "0 7px",
        backgroundColor: "transparent",
        fontWeight: "semiBold",

        "&:focus": {
          color: "blues.200",
          border: "1px solid",
          borderRadius: 4,
          outline: "none"
        },
        "&.active": {
          color: "blues.200",
          border: "none",
          borderRadius: 4,
          outline: "none"
        }
      },

      ".change-page": {
        padding: "1px 4px",
        height: 25,
        width: 25,
        borderRadius: 14,
        marginRight: 10,
        backgroundColor: "transparent",
        color: "greys.500",
        border: "1px solid",
        borderColor: "greys.150",

        "&:disabled": {
          color: "greys.150"
        },

        "&:last-of-type": {
          marginLeft: 10
        }
      },

      ".reset": {
        outline: "none",

        "&:active": {
          outline: "none",
          border: "none",
          color: "tertiary"
        }
      }
    }
  }
};

export default theme;
