export const pseudoRouteStatus = [
  {
    value: true,
    label: "Active"
  },
  {
    value: false,
    label: "Inactive"
  }
];

export const pseudoConstraints = [
  {
    label: "Maximum Shops",
    value: "max_shops"
  },
  {
    label: "Weight",
    value: "weight"
  }
];
