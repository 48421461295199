import axios from "axios";
const { REACT_APP_BASE_URL: API_BASE_URL } = process.env;

const options = {
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
};

const client = axios.create(options);
const baseClient = axios.create(options);

client.interceptors.request.use(config => {
  const token = JSON.parse(localStorage.getItem("twiga_dashboard_token"));
  if (token) {
    config.headers.Authorization = `Bearer ${token.access_token}`;
  }
  return config;
});

client.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) {
      // clear user data
      localStorage.setItem("twiga_dashboard_user", null);
      localStorage.setItem("twiga_dashboard_token", null);
      window.location.href = "/auth/login";
    }
    return Promise.reject(error);
  }
);

export { client, baseClient };
