import { useState } from "react";
import { OptionTypeBase } from "react-select";
import {
  GqlAreaV2Gql,
  GqlDepotV2Gql,
  GqlRegionV2Gql,
  GqlRegionV2GqlEdge,
  useRegionsQuery
} from "generated/graphql";
import { get } from "lodash-es";
import { useAuth } from "./useAuth";

export const UseRegionFilter = (country_ids?: string[]) => {
  const { user }: any = useAuth();
  const { loading: loadingRegions, data } = useRegionsQuery({
    variables: {
      filters: {
        country_ids: country_ids ? country_ids : user?.countries
      }
    }
  });
  const [filteredDepots, setFilteredDepots] = useState([]);

  const regions = get(data, "regions_v2.edges", []).map(
    ({ node }: GqlRegionV2GqlEdge) => node
  );
  const regionOptions = regions.map(
    ({ region_id: value, region_name: label }: OptionTypeBase) => ({
      value,
      label
    })
  );
  const onRegionChange = (option: OptionTypeBase) => {
    const areas = regions.find(
      ({ region_id }: GqlRegionV2Gql) => region_id === option?.value
    )?.areas;
    const depots = areas?.map(({ depots }: GqlAreaV2Gql) => depots).flat();
    const depotOptions = depots?.map(
      ({ depot_id: value, depot_name: label }: GqlDepotV2Gql) => ({
        value,
        label
      })
    );

    setFilteredDepots(depotOptions);
  };

  return {
    loadingRegions,
    filteredDepots,
    regionOptions,
    onRegionChange
  };
};
