import React, { useEffect } from "react";
import PseudoRouteForm from "components/psuedo-route-form";
import { useHistory } from "react-router-dom";
import { client } from "api";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { formatError } from "utils";
import { useFetchPseudoRouteByIdQuery } from "generated/graphql";

const labels = {
  weight: "Weight",
  max_shops: "Max Shops"
};

const EditPseudoRoute = () => {
  const [defaultValues, setDefaultValues] = React.useState<any>();
  const [httpError, setHttpError] = React.useState<any>(null);
  const [loadingState, setLoading] = React.useState(false);

  const history = useHistory();
  const pseudoRouteId = history.location.pathname.split("/").pop();

  const { data: psuedoRouteData, loading } = useFetchPseudoRouteByIdQuery({
    variables: {
      pseudo_route_id: pseudoRouteId as string
    }
  });

  useEffect(() => {
    if (psuedoRouteData?.pseudo_route_by_id) {
      const defaultVal: any = {
        name: psuedoRouteData.pseudo_route_by_id.name,
        constraints: psuedoRouteData.pseudo_route_by_id.constraints.map(
          constraint => ({
            value: constraint,
            label: labels[constraint as keyof typeof labels]
          })
        ),
        routes: psuedoRouteData.pseudo_route_by_id.routes.map(route => ({
          value: route?.route_id,
          label: route?.route_name
        }))
      };

      if (psuedoRouteData.pseudo_route_by_id.constraints.includes("weight")) {
        defaultVal["constraint_weight"] = { label: "Weight", value: "weight" };
        defaultVal["weight"] = JSON.parse(
          psuedoRouteData.pseudo_route_by_id.custom_constraints
        ).weight;
      }
      if (
        psuedoRouteData.pseudo_route_by_id.constraints.includes("max_shops")
      ) {
        defaultVal["max_shops"] = JSON.parse(
          psuedoRouteData.pseudo_route_by_id.custom_constraints
        ).max_shops;
      }

      setDefaultValues(defaultVal);
    }
  }, [psuedoRouteData]);

  const onSubmit = (data: any) => {
    const payload: any = {
      name: data.name,
      merged_routes: data.routes.map((route: any) => route.value),
      force_share: false,
      client_stamp: uuidv4(),
      constraints: data?.constraints?.map((constraint: any) => constraint.value)
    };

    if (!!data.weight && !!data.max_shops) {
      payload["custom_constraints"] = {
        weight: Number(data.weight),
        max_shops: Number(data.max_shops)
      };
    } else if (!!data.weight) {
      payload["custom_constraints"] = {
        weight: Number(data.weight)
      };
    } else if (!!data.max_shops) {
      payload["custom_constraints"] = {
        max_shops: Number(data.max_shops)
      };
    }
    setLoading(true);
    client
      .put(
        `/pseudo-routes/${psuedoRouteData?.pseudo_route_by_id?.pseudo_route_id}`,
        payload
      )
      .then(() => {
        history.push("/admin/pseudo-routes");
        toast.success("Pseudo Updated successfully");
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        setHttpError(formatError(error));
      });
  };

  return (
    <div>
      {loading && !!defaultValues ? (
        <div>Loading...</div>
      ) : (
        <PseudoRouteForm
          loading={loadingState}
          defaultValues={defaultValues}
          httpError={httpError}
          onSubmit={data => onSubmit(data)}
        />
      )}
    </div>
  );
};

export default EditPseudoRoute;
