import get from "lodash/get";
import { useCountriesQuery } from "generated/graphql";

export const useCountries = () => {
  const { loading: loadingCountries, data } = useCountriesQuery();

  const countriesData = get(data, "countries", []);
  const countries = countriesData.map(
    ({ country_id: value, iso_alpha2_name: label, iso_alpha2_code }) => ({
      value,
      label,
      iso_alpha2_code
    })
  );
  const countriesIsoCode = countriesData.map(
    ({ iso_alpha2_code }) => iso_alpha2_code
  );
  const currencies = countriesData.map(
    ({ default_currency }) => default_currency
  );

  return {
    loadingCountries,
    countriesData,
    countriesIsoCode,
    currencies,
    countries
  };
};
