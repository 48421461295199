import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Theme, ColorMode } from "theme-ui";

interface themeProps extends Theme {
  colors: ColorMode;
}

export const ImgPreview = styled.img`
  width: 100%;
  height: auto;
  max-height: 225px;
`;

type props = {
  theme?: themeProps;
  width?: number;
  height?: number;
};

export const UploadImageWrapper = styled.div`
  ${({ theme, width, height }: props) => css`
    width: ${width ? `${width}px` : "120px"};
    height: ${height ? `${height}px` : "120px"};
    background-color: ${theme?.colors.cloudWhite};
    display: table;
    margin: 0px 25px;
    border-radius: 4px;
    border-color: ${theme?.colors.grey150} !important;
    border: 1px dashed;

    label {
      cursor: pointer;
      display: table-cell !important;
      color: ${theme?.colors.grey300} !important;
      font-weight: normal !important;
      height: 100%;
      width: 100%;
      padding: 8px;
      text-align: center;
      vertical-align: middle;
    }
  `}
`;

export const View = styled.div`
  ${({ theme }: { theme?: themeProps }) => css`
    margin: 20px 0px;

    input[type="file"] {
      display: none;
      outline: none;
    }

    .remove-file {
      color: ${theme?.colors.grey500};
      font-weight: 600;
      background-color: transparent;
      cursor: pointer;
      margin-left: 9px;
    }

    p {
      display: inline-flex;
      overflow-wrap: break-word;
    }
  `}
`;
