import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const Container = styled.div`
  ${({ theme }) => css`
    .NOTPAID {
      color: ${theme.colors.notifications.error};

      .dot {
        height: 10px;
        width: 10px;
        background-color: ${theme.colors.notifications.error};
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
      }
    }

    .FULLYPAID {
      color: ${theme.colors.green};

      .dot {
        height: 10px;
        width: 10px;
        background-color: ${theme.colors.green};
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
      }
    }

    .PARTIALLYPAID {
      color: ${theme.colors.butterCup};

      .dot {
        height: 10px;
        width: 10px;
        background-color: ${theme.colors.butterCup};
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
      }
    }

    .INPROGRESS {
      color: ${theme.colors.darkBlue};

      .dot {
        height: 10px;
        width: 10px;
        background-color: ${theme.colors.darkBlue};
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
      }
    }
  `}
`;

const paymentStatusTypes = {
  NOTPAID: "Not Paid",
  FULLYPAID: "Fully Paid",
  PARTIALLYPAID: "Partially Paid",
  INPROGRESS: "In Progress"
};

const PaymentStatus = ({ status }) => {
  const statusText = paymentStatusTypes[status];
  return (
    <Container>
      <div className={`${status}`}>
        <span className="dot" />
        {statusText}
      </div>
    </Container>
  );
};

export default PaymentStatus;
