/** @jsx jsx */
import React, { useEffect } from "react";
import { useTable, usePagination, useExpanded } from "react-table";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import { isEmpty } from "lodash-es";
import { jsx, Flex, Box } from "theme-ui";
import Pagination from "components/table/Pagination";
import { TableLoading } from "./Loading";
import { Loading } from "components";
import { NoData } from "components/table/styles";
import { TableWrapper, PaginationText } from "./styles";

const Table = ({
  columns,
  data,
  pagination,
  pageSize = 10,
  currentPage = 1,
  hasNext,
  totalCount,
  placeholder,
  totalPages,
  hasPrevious,
  loading = false,
  error = false,
  setCurrPage,
  renderRowSubComponent,
  getCellProps,
  initialState,
  getRowProps = () => ({})
}) => {
  const getRowNumber = index => {
    const prevPage = currentPage - 1;
    const rowNumber = prevPage * pageSize + index;
    return rowNumber;
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage
  } = useTable(
    {
      columns,
      data,
      getRowNumber,
      manualPagination: true,
      pageCount: totalPages,
      initialState: {
        ...initialState,
        pageIndex: 0,
        pageSize: pageSize
      },
      autoResetExpanded: false
    },
    useExpanded,
    usePagination
  );

  useEffect(() => {
    if (setCurrPage) {
      setCurrPage(pageIndex + 1);
    }
  }, [pageIndex, setCurrPage]);

  if (loading) {
    return (
      <TableLoading>
        <Loading />
      </TableLoading>
    );
  }

  return (
    <div>
      <TableWrapper>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map(column => (
                  <th
                    width={column.width}
                    {...column.getHeaderProps()}
                    key={column.id}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <React.Fragment key={index}>
                  <tr
                    sx={{
                      backgroundColor: row.isExpanded ? "accent" : "white",
                      color:
                        !row.original.active || row.original.cancelled
                          ? "red"
                          : null
                    }}
                    className="cancelled"
                    {...row.getRowProps(getRowProps(row))}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps(
                            getCellProps && getCellProps(cell)
                          )}
                          key={uuidv4()}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr
                      sx={{
                        backgroundColor: "accent"
                      }}
                      className="expanded-row"
                    >
                      <td colSpan={visibleColumns.length}>
                        {renderRowSubComponent({ row })}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        {isEmpty(data) && !loading && (
          <NoData>
            <p>{placeholder || "NO DATA"}</p>
          </NoData>
        )}
        {error && (
          <NoData>
            <p>Error loading data. Please try again later</p>
          </NoData>
        )}
      </TableWrapper>
      {pagination && (
        <Flex className="pagination-flex">
          <Box className="pagination-box">
            <PaginationText>
              Showing{" "}
              {currentPage === 1
                ? currentPage
                : currentPage * pageSize - (pageSize - 1)}{" "}
              - {hasNext ? currentPage * pageSize : totalCount} of {totalCount}{" "}
              entries
            </PaginationText>
          </Box>
          <Box className="pagination">
            <Pagination
              canPreviousPage={hasPrevious}
              canNextPage={hasNext}
              gotoPage={gotoPage}
              nextPage={nextPage}
              previousPage={previousPage}
              page={page}
              pageCount={totalPages}
              pageIndex={currentPage - 1}
              currPage={currentPage}
            />
          </Box>
        </Flex>
      )}
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
};

export default Table;
