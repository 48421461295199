import React, { useEffect, useRef } from "react";
import ReactDom from "react-dom";
import close_modal from "assets/close_modal.svg";
import { Overlay, ModalStyles, CloseIcon, TitleContainer } from "./styles";

type props = {
  title: string;
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
};

const Modal = ({ title, open, children, onClose }: props) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    // event listener function to close modal if user clicks outside of modal component
    const onClickOutside = (e: { target: any; }) => {
      if (ref?.current?.contains && !ref.current.contains(e.target)) {
        onClose();
      }
    };
    document.addEventListener("mouseup", onClickOutside);
    return () => {
      document.removeEventListener("mouseup", onClickOutside);
    };
  }, [onClose, ref]);

  useEffect(() => {
    // event listener to close modal on pressing esc key
    const closeOnEscapeKey = (e: { key: string; }) => (e.key === "Escape" ? onClose() : null);
    document.body.addEventListener("keydown", closeOnEscapeKey);
    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKey);
    };
  }, [onClose]);

  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <Overlay />
      <ModalStyles ref={ref}>
        <TitleContainer>
          <CloseIcon onClick={onClose}>
            <img src={close_modal} alt="close icon" />
          </CloseIcon>
          {title}
        </TitleContainer>
        {children}
      </ModalStyles>
    </>,
    document.getElementById("portal")!
  );
};

export default Modal;
