import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

const Label = styled.label`
  position: relative;
  display: block;
  margin: 15px 0 0;

  span {
    display: inline-block;
    margin: 10px 10px 10px 0;

    &.inline {
      min-width: 130px;
    }

    &.block {
      display: block;
    }
  }

  .input-box {
    position: relative;

    i {
      position: absolute;
      top: 11px;
      left: 12px;
      font-size: 18px;
      color: #707070;
    }
  }

  .input-error {
    color: red;
    font-size: 12px;
    margin: 5px 0 0;
    min-height: 15px;
  }

  input {
    position: relative;
    background: #f6f6f6;
    padding: 12px;
    border-radius: 4px;
    outline: none;
    transition: all 0.25s linear;
    color: #707070;
    font-size: 14px;
    border: 1px solid #e8e8e8;

    @media (min-width: 1280px) {
      border-color: transparent;
    }

    &.bordered {
      border: 1px solid #d4d4d4;
    }

    ::placeholder {
      color: #a3a3a3;
    }

    &.has-icon {
      padding-left: 40px;
    }

    &.inline {
      min-width: 240px;
    }

    &.block {
      display: block;
      width: 100%;
    }

    &:focus {
      border-color: #316aff;
    }

    &.has-error {
      border: 1px solid red;
      color: red;
    }
  }
`;

export default function Input({
  name,
  type,
  label,
  placeholder,
  display,
  children,
  formik
}) {
  const errors = formik.errors[name];
  const touched = formik.touched[name];

  return (
    <Label>
      <span className={display}>{label}</span>
      <div className="input-box">
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          className={`${display} ${children ? "has-icon" : ""} ${
            errors && touched ? "has-error" : ""
          }`}
          {...formik.getFieldProps(name)}
        />
        {children && <i>{children}</i>}
      </div>
      {touched && errors && <div className="input-error">{errors}</div>}
    </Label>
  );
}

Input.defaultProps = {
  type: "text",
  label: "",
  placeholder: "",
  display: "block"
};

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  display: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  formik: PropTypes.any.isRequired
};
