import React from "react";
import styled from "@emotion/styled";

const Spinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  font-family: "Oxygen", sans-serif;
  font-size: 14px;
  width: 100px;
  height: 140px;
  margin: -70px 0 0 -50px;
  z-index: 100;
  .loader {
    margin: 3px auto 15px;
    border: 8px solid #f3f3f3;
    border-top-color: #211651;
    border-bottom-color: #a399b6;
    border-right-color: #776a93;
    border-left-color: #d1cbd9;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    animation: spin 1s linear infinite;
  }
  .zone {
    color: #1c174c;
    font-weight: bold;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const PageLoader = styled.div`
  position: relative;
  background: #fff;
  padding: 40px;
  min-height: 550px;
  width: 100%;
`;

export const FormLoader = styled.div`
  position: relative;
  background: #fff;
  padding: 40px;
  min-height: 650px;
  width: 100%;
`;

export default function Loading() {
  return (
    <Spinner>
      <div className="loader" />
      <div className="zone">Loading...</div>
      <div className="wait">Please Wait</div>
    </Spinner>
  );
}
