/** @jsx jsx */
import { jsx, Theme, ColorMode } from "theme-ui";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

const Badge = ({ children }: { children: React.ReactNode }) => {
  return <BadgeComponent>{children}</BadgeComponent>;
};

interface themeProps extends Theme {
  colors: ColorMode;
}

const BadgeComponent = styled.div`
  ${({ theme }: { theme?: themeProps }) => css`
    background-color: ${theme?.colors.badgeGreen};
    color: ${theme?.colors.white};
    width: 22px;
    min-width: fit-content;
    border-radius: 50%;
    padding: 4px;
    height: 22px;
    text-align: center;
    font-size: 12px;
  `}
`;

export default Badge;
