import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const HorizontalRule = styled.div`
  ${({ theme }) => css`
    margin: 30px 0px;
    height: 1px;
    background-color: ${theme.colors.border};
  `}
`;

interface FormLayoutProps {
  width?: number;
}

export const FormLayout = styled.div<FormLayoutProps>`
  ${({ theme, width }) => css`
    text-align: center;
    margin: 50px 0;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
    .scrollable-element {
      scrollbar-color: red yellow;
    }

    form {
      font-family: ${theme.fonts.body};
      width: ${`${width}px`};
      background-color: white;
      padding-bottom: 40px;
      display: inline-block;
      text-align: left;

      .datepicker {
        border-radius: 4px;
        width: 210px;
      }
      .react-datepicker-wrapper {
        display: block;
      }

      h1 {
        background-color: ${theme.colors.primary};
        color: white;
        text-align: center;
        font-size: ${theme.fontSizes[5]};
        padding: 15px 0px;
        margin-top: 0px;
        font-weight: ${theme.fontWeights.semiBold};
        border-radius: 4px 4px 0px 0px;
      }

      h2 {
        background-color: ${theme.colors.primary};
        color: white;
        text-align: center;
        font-size: ${theme.fontSizes[1]};
        padding: 10px 0px;
        margin-top: 0px;
        font-weight: ${theme.fontWeights.semiBold};
        border-radius: 4px 4px 0px 0px;
      }

      label {
        display: inline-block;
        color: ${theme.colors.primary};
        font-weight: ${theme.fontWeights.semiBold};
        margin-top: 15px;
        margin-bottom: 4px;
      }

      &.block-form {
        label: {
          display: block;
        }
      }

      input {
        width: -webkit-fill-available;
        display: block;
        color: black;
        font-size: 13px;
        padding: 6px 12px;
        background-color: white;
        border: 1px solid;
        border-color: ${theme.colors.greys[500]};
        height: 37px;
        font-family: ${theme.fonts.body};
        font-weight: 400px;

        &::placeholder {
          font-family: ${theme.fonts.body};
          font-weight: 400px;
          color: ${theme.colors.greys[500]};
        }

        :disabled {
          background-color: ${theme.colors.greys[250]};
          border-color: ${theme.colors.greys[150]};
          color: ${theme.colors.greys[500]};
          opacity: 0.7;

          :hover {
            border-color: ${theme.colors.greys[500]};
          }
        }
      }

      select {
        width: -webkit-fill-available;
        display: block;
        font-family: ${theme.fonts.body};
        font-weight: 400px;
        font-size: 13px;
        color: ${theme.colors.greys[500]};
        padding: 6px 12px;
        background-color: white;
        border: 1px solid;
        border-color: ${theme.colors.greys[500]};
        border-radius: 4px;
        height: 37px;

        &::placeholder {
          font-family: ${theme.fonts.body};
          font-weight: 400px;
          color: ${theme.colors.greys[500]};
          font-size: 13px;
        }
      }

      span {
        color: red;
        font-size: ${theme.fontSizes[2]};
        display: block;
      }

      .plus {
        width: 35px;
      }

      .footer {
        float: right;
        padding-right: 40px;
      }
    }
  `}
`;

export const ModalForm = styled.div`
  ${({ theme }) => css`
    width: 500px;
    form {
      font-family: ${theme.fonts.body};
      width: inherit;
      padding: 0 20px 20px 20px;
      background-color: white;
      display: inline-block;
      text-align: left;

      .datepicker {
        border-radius: 4px;
        width: 210px;
      }
      .react-datepicker-wrapper {
        display: block;
      }

      label {
        display: inline-block;
        color: ${theme.colors.primary};
        font-weight: ${theme.fontWeights.semiBold};
        margin-top: 15px;
        margin-bottom: 4px;
      }

      input {
        width: -webkit-fill-available;
        display: block;
        color: black;
        font-size: 13px;
        padding: 6px 12px;
        background-color: white;
        border: 1px solid;
        border-color: ${theme.colors.greys[500]};
        border-radius: 4px;
        height: 37px;
        font-family: ${theme.fonts.body};
        font-weight: 400px;

        &::placeholder {
          font-family: ${theme.fonts.body};
          font-weight: 400px;
          color: ${theme.colors.greys[500]};
        }

        :disabled {
          background-color: ${theme.colors.greys[250]};
          border-color: ${theme.colors.greys[150]};
          color: ${theme.colors.greys[500]};
          opacity: 0.7;

          :hover {
            border-color: ${theme.colors.greys[500]};
          }
        }
      }

      select {
        width: -webkit-fill-available;
        display: block;
        font-family: ${theme.fonts.body};
        font-weight: 400px;
        font-size: 13px;
        color: ${theme.colors.greys[500]};
        padding: 6px 12px;
        background-color: white;
        border: 1px solid;
        border-color: ${theme.colors.greys[500]};
        border-radius: 4px;
        height: 37px;

        &::placeholder {
          font-family: ${theme.fonts.body};
          font-weight: 400px;
          color: ${theme.colors.greys[500]};
          font-size: 13px;
        }
      }

      textarea {
        width: 100%;
        font-family: inherit;
      }

      span {
        color: red;
        font-size: ${theme.fontSizes[2]};
        display: block;
      }
      .footer {
        float: right;
      }
    }
  `}
`;

export const LoadingSpinner = styled.div`
  ${({ theme }) => css`
    svg {
      width: 25px;
      height: 18px;
      margin-right: 5px;
    }
  `}
`;

export default FormLayout;
