const buildCloudinaryUrl = ({
  url,
  width,
  height
}: {
  url: string;
  width: number;
  height: number;
}) => {
  const cloudinaryUrl = url?.replace(
    "https://storage.googleapis.com/",
    `https://res.cloudinary.com/twiga-foods/image/upload/q_auto,f_auto,l_water-mark,y_0,w_${width},h_${height},c_fit/`
  );
  return cloudinaryUrl;
};

export default buildCloudinaryUrl;
