/** @jsx jsx */
import { jsx } from "theme-ui";
import styled from "@emotion/styled";
import UPLOAD from "assets/upload-img.svg";

export const ImageHolder = () => (
  <NoImageIcon src={UPLOAD} alt="upload icon" />
);

const NoImageIcon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
